import React, { useRef } from 'react';
import SEO from '../components/SEO';
// import ScrollToTop from '../utils/scrollToTop';
import Hero from '../components/Hero';
import About from '../components/About';
import Features from '../components/Features';
import ProjectLoad from '../components/ProjectLoad';
import Testimonials from '../components/Testimonials';
import CtaForm from '../components/CtaForm';
import Footer from '../components/Footer';
import { useSiteMetadata } from '../utils/useSiteMetadata';
import { useHasBeenPartlyVisible } from '../utils/useVisibility';

export default function IndexPage() {
  const {
    site: {
      siteMetadata: { title, description, siteUrl, ogImage, favicon, lang },
    },
  } = useSiteMetadata();
  const halfPage = useRef();
  const hasScrolled = useHasBeenPartlyVisible(halfPage, 0.3);
  return (
    <>
      <SEO
        title={title}
        description={description}
        keywords="
          Freelancer,
          Web developer,
          Web development,
          Python,
          JavaScript,
          Applications,
          freelancing,
          freelance web developer for hire,
          freelance web developer portfolio,
          apis,
          web apps,
          programming,
          python projects,
          react freelancer,
          freelance web developer near me,
          gatsby,
          freelance web developer work from home,
          static website"
        siteUrl={siteUrl}
        ogImage={ogImage}
        favicon={favicon}
        lang={lang}
        type="website"
        page="Home"
        path=""
      />
      {/* <ScrollToTop /> */}
      <Hero />
      {/* <h1 className="text-red-500">
        if the how (from above) is not clear make it clearer || this is more like a what I do now a how answer
      </h1> */}
      <About />
      {hasScrolled ? (
        <>
          <Features />
          <ProjectLoad />
          <Testimonials />
          <CtaForm />
          <Footer />
        </>
      ) : (
        <div className="h-screen" ref={halfPage} />
      )}
      {/* <h1 className="text-red-500">where? chat popup</h1> */}
      {/* <h1 className="text-red-500">
        maybe add at the footer a way to schedule a meeting
      </h1> */}
    </>
  );
}
