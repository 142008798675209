import React, { useEffect, useState } from 'react';
import InView from 'react-intersection-observer';
import CounterAnimation from '../utils/CounterAnimation';

export default function SubHero({ dataCount, data }) {
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(1);

  useEffect(() => {
    // const intervalId = setInterval(() => {
    setFrom(to);
    setTo(Math.floor(1));
    // }, 8000);
    // return () => {
    //   clearInterval(intervalId);
    // };
  }, [to]);

  return (
    <>
      <div className="mb-4 text-justify">
        <InView as="div" onChange={() => setTo(to + 1)}>
          <h1
            className="capitalize text-2xl font-semibold leading-10 tracking-tight 
          sm:text-3xl 
          md:"
          >
            <CounterAnimation from={from} to={parseInt(dataCount)} /> +
          </h1>
          <h1
            className="capitalize text-2xl font-semibold leading-10 tracking-tight 
          sm:text-3xl 
          md:"
          >
            {data}
          </h1>
        </InView>
        <div className="mt-2">
          <div className="w-56 h-1 animated-underline inline-flex rounded-full" />
        </div>
      </div>
    </>
  );
}
