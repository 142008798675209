import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/style.css';
import '../styles/hamburger.css';
import PropTypes from 'prop-types';

export default function SEO({
  children,
  title,
  description,
  keywords,
  siteUrl,
  ogImage,
  favicon,
  lang,
  type,
  page,
  path,
}) {
  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="keywords" content={keywords} />
      {/* Meta Tags */}
      <meta name="description" content={description} />
      <meta name="image" content={`${siteUrl}/${ogImage}`} />
      <meta name="image:alt" content={description} />
      {/* Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={page} />
      <meta property="og:url" content={`${siteUrl}${path}`} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${siteUrl}/${ogImage}`} />
      <meta property="og:image:alt" content={description} />
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={page} />
      <meta name="twitter:url" content={`${siteUrl}${path}`} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${siteUrl}/${ogImage}`} />
      <meta name="twitter:image:alt" content={description} />
      {children}
    </Helmet>
  );
}

SEO.propTypes = {
  /** The site title */
  title: PropTypes.string.isRequired,
  /** The site description */
  description: PropTypes.string.isRequired,
  /** Keywords to use in meta keywords */
  keywords: PropTypes.string.isRequired,
  /** The site URL */
  siteUrl: PropTypes.string.isRequired,
  /** Image url to use for opengraph image */
  ogImage: PropTypes.string,
  /** Favicon image urls* */
  favicon: PropTypes.shape({
    svg: PropTypes.string,
    ico: PropTypes.string,
    sm: PropTypes.string,
    lg: PropTypes.string,
  }),
  /** Lang to use as meta lang */
  lang: PropTypes.string.isRequired,
  /** The type of meta - useful for Facebook */
  type: PropTypes.oneOf(['website', 'article']).isRequired,
  /** The page name */
  page: PropTypes.string.isRequired,
  /** The path to the page */
  path: PropTypes.string.isRequired,
};
