import React from 'react';
import { motion } from 'framer-motion';
import checkmarks from '../../static/checkmarks.svg';
import FadeInWhenVisible from '../utils/FadeInWhenVisible';
import devIcon from '../../static/dev-icon.svg';
import SectionTitle from './SectionTitle';
import GetImgViaFilename from '../utils/GetImgViaFilename';

const item1 = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: 144 },
};
const item2 = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: -144 },
};

function ProjectTemplate({ projectsToRender }) {
  return (
    <section className="pt-12 pb-10 sm:py-16 md:px-4" id="projectSection">
      <SectionTitle
        title="Projects I’ve Built"
        subTitle="Some featured projects"
      />
      {projectsToRender.map((project, index) => (
        <div
          key={index}
          className="my-12 md:px-4 lg:grid lg:grid-cols-2 lg:mt-32 lg:mb-16 lg:px-0"
        >
          <div
            className={`mb-10 px-4 ${(project.shift &&
              'lg:col-start-1 lg:row-start-1 2xl:ml-24 3xl:ml-36') ||
              'lg:col-start-2 lg:row-start-1'
              } md:px-0`}
          >
            <img src={devIcon} alt="icon" className="inline mr-1 mb-5" />
            <h1
              className="text-2xl mb-5 capitalize font-semibold
            md:text-3xl"
            >
              {project.title}
            </h1>

            <p className="2xl:w-4/5">{project.desc}</p>
          </div>
          <div
            className={`mx-auto ${(project.shift &&
              'lg:col-start-2 lg:row-span-2 lg:justify-self-end lg:m-0 2xl:justify-self-center 2xl:mx-auto') ||
              'lg:col-start-1 lg:row-span-2 lg:justify-self-start lg:m-0 2xl:justify-self-center 2xl:mx-auto'
              }`}
          >
            <div>
              <FadeInWhenVisible>
                <motion.div
                  variants={(project.shift && { ...item1 }) || { ...item2 }}
                  transition={{ duration: .8 }}
                >
                  <div
                    // className=" hover:shadow-purple"
                    className="scrollable-screenshot hover:shadow-purple"
                    style={{ margin: '0 auto' }}
                  >
                    <a
                      className="overflow-y-hidden"
                    // style={{
                    //   backgroundImage: `../../static/projects-screeshots/${project.screenshotLink}`,
                    // }}
                    // style={{ position: `relative` }}
                    >
                      {/* <img
                      src={`./projects-screeshots/${project.screenshotLink}`}
                      alt=""
                      // className="tobehovered"
                    /> */}
                      <GetImgViaFilename
                        filename={`${project.screenshotLink}`}
                        alt={`${project.screenshotAlt}`}
                        className="tobehovered min-h-480"
                      />
                      <span className="opacity-0">{project.screenshotAlt}</span>
                    </a>
                  </div>
                </motion.div>
              </FadeInWhenVisible>
            </div>
          </div>
          <div
            className={`${(project.shift &&
              'lg:col-start-1 lg:row-start-2 2xl:ml-24 3xl:ml-36') ||
              'lg:col-start-2 lg:row-start-2'
              }`}
          >
            <div
              className="mx-auto flex flex-wrap px-2
            lg:px-0"
            >
              <div
                className="w-full my-10
                sm:w-10/12 sm:mx-auto
                md:w-full
                2xl:mt-5 mb-0"
              >
                <ul
                  className="grid grid-cols-2 grid-rows-3 gap-4
                sm:gap-y-4 sm:gap-x-24
                md:ml-8
                lg:m-0 lg:gap-x-8
                4xl:w-10/12 "
                >
                  {project.bulletPoints.bulletPointsData.map((bullet, i) => (
                    <li key={i}>
                      <span className="flex md:text-lg">
                        <img src={checkmarks} alt="icon" className="mr-1" />
                        {bullet}
                      </span>
                    </li>
                  ))}
                </ul>
                <div className="flex justify-center mt-10 2xl:mt-16 xl:justify-start">
                  <a href={`${project.livePreview}`} className={`${(project.livePreview && 'btn-primary') || 'btn-muted'} `} aria-label="Live project preview" target="_blank">{(project.livePreview && 'Live preview') || 'Private'}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
}

export default ProjectTemplate;
