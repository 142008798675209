import React from 'react';
import testimoniaIcon from '../../static/testimonial-icon.svg';

export default function TestimonialCard({ message, name, company, position }) {
  return (
    <div
      className={`py-4 w-full mx-auto grid justify-items-center
        sm:w-full
        md:w-full 
        lg:px-0
        xl:flex-row xl:justify-center xl:w-full
        `}
    >
      <div className="md:w-2/3 lg:w-11/12 xl:w-full xl:px-4">
        <div
          className={`rounded bg-purple-400 bg-opacity-25 p-4 rounded-bl-xl rounded-br-xl rounded-t-sm h-84
            ${position === 'l' ? 'xl:rounded-sm xl:rounded-bl-xl' : ''} ${position === 'r' ? 'xl:rounded-sm xl:rounded-br-xl' : ''
            } `}
          style={{
            background:
              'linear-gradient(175deg, rgba(116, 227, 231, 0.6) 0%, rgba(176, 146, 236, 0.3) 39.58%, rgba(154, 116, 231, 0.60) 100%)',
          }}
        >
          <div
            className="w-14 h-14 bg-gray-100 rounded-full mb-4 flex-shrink-0 p-2 flex "
            style={{
              boxShadow: 'rgba(106, 82, 159, 0.5) 0px 0px 5px 0px inset',
            }}
          >
            <img src={testimoniaIcon} alt="testimonial icon" />
          </div>
          <div className="flex-grow">
            <h1
              className="text-lg text-left break-words md:break-normal mb-3"
              style={{ minHeight: '120px' }}
            >
              {message}
            </h1>
            <p
              className="text-base
              md:text-lg"
            >
              {name}
            </p>
            <span>{company}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
