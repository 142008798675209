import React from 'react';

export default function Card({
  title,
  desc,
  shift,
  position,
  iconPath,
}) {
  return (
    <div
      className={`flex flex-col mb-6 py-4 px-4 w-full mx-auto
        sm:w-11/12
        md:w-full ${shift ? 'md:flex-row-reverse xl:flex-row' : ''}
         lg:px-0
        xl:flex-row xl:justify-center xl:w-full
        `}
    >
      <div className="md:w-2/3 xl:w-full xl:px-4 max-w-480">
        <div
          className={`rounded bg-opacity-25 p-4 rounded-bl-xl rounded-br-xl rounded-t-sm h-82 md:h-104 xl:h-88 5xl:w-11/12 5xl:mx-auto 6xl:w-11/12 
            ${position === 'l' ? 'xl:rounded-sm xl:rounded-bl-xl' : ''} ${position === 'r' ? 'xl:rounded-sm xl:rounded-br-xl' : ''
            } `}
          style={{
            background:
              'linear-gradient(180deg, rgba(116, 227, 231, 0.6) 0%, rgba(176, 146, 236, 0.35) 39.58%, rgba(154, 116, 231, 0.60) 100%)',
            // background:
            //   'linear-gradient(180deg, rgba(117, 224, 230, 0.3864) 0%, rgba(93, 143, 212, 0.37975) 39.58%, rgba(57, 19, 184, 0.3696) 100%)',
            boxShadow: '-5px 5px 45px rgba(0, 0, 0, 0.15)',
          }}
        >
          <div
            className="w-14 h-14 bg-gray-100 rounded-full mb-5 flex-shrink-0 p-2"
            // style={{ boxShadow: 'inset 0px 0px 10px 1px rgb(0 0 0 / 10%)' }} rgb(106 82 159 / 50%) 0px 0px 7px 1px inset
            style={{ boxShadow: 'rgb(106 82 159 / 50%) 0px 0px 5px 0px inset' }}
          >
            <img src={iconPath} alt="test" />
          </div>
          <div className="flex-grow">
            <h1
              className="text-2xl font-semibold mb-3
              md:text-3xl"
            >
              {title}
            </h1>
            <p
              className="text-base
              md:text-lg"
            >
              {desc}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
