import React, { useState } from 'react';
import { useFormik } from 'formik';
import SectionTitle from './SectionTitle';

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required';
  } else if (values.name.length > 24) {
    errors.name = 'Must be 24 characters or less';
  }
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.message) {
    errors.message = 'Required';
  }

  return errors;
};
export default function CtaForm() {
  const [isChecked, setIsChecked] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const replaceItemInState = (index) => {
    const newState = [...isChecked];
    newState[index] = !newState[index];
    setIsChecked(newState);
  };

  const [radioChecked, setRadioChecked] = useState([false, false, false]);
  const replaceItemInStateRadio = (index) => {
    let newState = [...radioChecked];

    if (newState.includes(true)) {
      if (newState[index] === true) {
        newState = [false, false, false];
      } else {
        newState = [false, false, false];
        newState[index] = !newState[index];
      }
    } else {
      newState[index] = !newState[index];
    }
    setRadioChecked(newState);
  };
  const [isActive, setActive] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validate,
    onSubmit: (values, actions) => {
      fetch(`${process.env.GATSBY_SERVERLESS_BASE}/sendForm`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      setActive(!isActive);
      // alert(JSON.stringify(values, null, 2));
      // actions.setSubmitting(false);
      actions.resetForm({
        values: {
          name: '',
          email: '',
          message: '',
        },
      });
      setIsChecked([false, false, false, false, false]);
      setRadioChecked([false, false, false]);
    },
  });
  return (
    <>
      <section className="py-12 4xl:px-48 px-4 overflow-x-hidden" id="contactSection">
        <div className="">
          <SectionTitle
            title="contact me"
            subTitle="Let's start building"
            className="mt-20"
          />
          <div
            className="mx-auto lg:w-5/6 rounded rounded-bl-xl rounded-tr-xl 3xl:w-4/6"
            style={{
              background:
                'linear-gradient(180deg, rgba(117, 224, 230, 0.3864) 0%, rgba(93, 143, 212, 0.37975) 39.58%, rgba(57, 19, 184, 0.3696) 100%)',
              // 'linear-gradient(180deg, rgba(117, 224, 230, 0.3864) 100%, rgba(93, 143, 212, 0.37975) 100%, rgba(57, 19, 184, 0.3696) 100%)',
            }}
          >
            <div className="relative px-4 py-10 md:mx-0 rounded-3xl w-100">
              <div className="max-w-md mx-auto lg:max-w-lg xl:max-w-2xl 2xl:max-w-3xl 4xl:max-w-2xl">
                <div className="pt-4 lg:pt-8">
                  <div className="block text-gray-800">
                    <h1 className="text-2xl font-semibold leading-relaxed ">
                      Let's Talk Further
                    </h1>
                    <p className="text-base font-normal leading-relaxed">
                      Get in touch to discuss your project and advise you how I would
                      add value to your business.
                    </p>
                  </div>
                </div>
                <div className="divide-y divide-gray-200">
                  <form
                    className="py-8 text-base leading-6 text-gray-800 sm:text-lg sm:leading-7"
                    onSubmit={formik.handleSubmit}
                  >
                    <fieldset>
                      <div className="flex flex-col pb-2">
                        <label htmlFor="name" className="leading-loose font-semibold">
                          Full name{' '}
                          {formik.touched.name && formik.errors.name ? (
                            <span
                              className="text-red-500 font-bold"
                              title={formik.errors.name}
                            >
                              *
                            </span>
                          ) : null}
                        </label>
                        <input
                          type="text"
                          name="name"
                          className={`px-4 py-2 border focus:ring-1 focus:ring-gray-800 focus:border-transparent focus:bg-white w-full sm:text-sm border-gray-600 rounded-md focus:outline-none text-gray-800 bg-transparent ${formik.touched.name && formik.errors.name
                            ? 'focus:ring-1 focus:ring-red-500 focus:border-none border-red-500'
                            : 'focus:border-none'
                            }`}
                          placeholder="Full name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                          autoComplete="off"
                          maxLength="96"
                        />
                      </div>
                      <div className="flex flex-col pb-2">
                        <label htmlFor="email" className="leading-loose font-semibold">
                          Email{' '}
                          {formik.touched.email && formik.errors.email ? (
                            <span
                              className="text-red-500 font-bold"
                              title={formik.errors.email}
                            >
                              *
                            </span>
                          ) : null}
                        </label>
                        <input
                          type="text"
                          name="email"
                          className={`px-4 py-2 border focus:ring-1 focus:ring-gray-800 focus:border-transparent focus:bg-white w-full sm:text-sm border-gray-600 rounded-md focus:outline-none text-gray-800 bg-transparent ${formik.touched.email && formik.errors.email
                            ? 'focus:ring-1 focus:ring-red-500 focus:border-none border-red-500'
                            : 'focus:border-none'
                            }`}
                          placeholder="Your Email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          autoComplete="off"
                        />
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className="flex flex-col pb-2 gap-y-1">
                        <p className="leading-loose font-semibold">Interested in</p>
                        <div className="xl:grid xl:grid-cols-2">
                          <div className="grid pb-1 xl:pb-0">
                            <label
                              className="checkbox path flex capitalize"
                              htmlFor="interest-one"
                            >
                              <input
                                type="checkbox"
                                id="interest-one"
                                name="checked"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="Website design"
                                checked={isChecked[0]}
                                onClick={() => {
                                  replaceItemInState(0);
                                }}
                              />
                              <svg viewBox="0 0 21 21">
                                <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186" />
                              </svg>
                              <span
                                className="ml-2"
                                style={{ lineHeight: '23px' }}
                              >
                                Website design
                              </span>
                            </label>
                          </div>
                          <div className="grid pb-1 xl:pb-0">
                            <label
                              className="checkbox path flex capitalize"
                              htmlFor="interest-two"
                            >
                              <input
                                type="checkbox"
                                id="interest-two"
                                name="checked"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="Website development"
                                checked={isChecked[1]}
                                onClick={() => {
                                  replaceItemInState(1);
                                }}
                              />
                              <svg viewBox="0 0 21 21">
                                <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186" />
                              </svg>
                              <span
                                className="ml-2"
                                style={{ lineHeight: '23px' }}
                              >
                                Website development
                              </span>
                            </label>
                          </div>
                          <div className="grid pb-1 xl:pb-0">
                            <label
                              className="checkbox path flex capitalize"
                              htmlFor="interest-three"
                            >
                              <input
                                type="checkbox"
                                id="interest-three"
                                name="checked"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="React app"
                                checked={isChecked[2]}
                                onClick={() => {
                                  replaceItemInState(2);
                                }}
                              />
                              <svg viewBox="0 0 21 21">
                                <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186" />
                              </svg>
                              <span
                                className="ml-2"
                                style={{ lineHeight: '23px' }}
                              >
                                React APP
                              </span>
                            </label>
                          </div>
                          <div className="grid pb-1 xl:pb-0">
                            <label
                              className="checkbox path flex capitalize"
                              htmlFor="interest-four"
                            >
                              <input
                                type="checkbox"
                                id="interest-four"
                                name="checked"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="Python Scrapping/Script"
                                checked={isChecked[3]}
                                onClick={() => {
                                  replaceItemInState(3);
                                }}
                              />
                              <svg viewBox="0 0 21 21">
                                <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186" />
                              </svg>
                              <span
                                className="ml-2"
                                style={{ lineHeight: '23px' }}
                              >
                                Python Scrapping/Script
                              </span>
                            </label>
                          </div>
                          <div className="grid pb-1 xl:pb-0">
                            <label
                              className="checkbox path flex capitalize"
                              htmlFor="interest-five"
                            >
                              <input
                                type="checkbox"
                                id="interest-five"
                                name="checked"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value="SEO"
                                checked={isChecked[4]}
                                onClick={() => {
                                  replaceItemInState(4);
                                }}
                              />
                              <svg viewBox="0 0 21 21">
                                <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186" />
                              </svg>
                              <span
                                className="ml-2"
                                style={{ lineHeight: '23px' }}
                              >
                                SEO
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className="flex flex-col pb-2 gap-y-1">
                        <p className="leading-loose font-semibold">
                          When do you want to launch?
                        </p>
                        <div className="grid pb-1 xl:pb-0">
                          <label
                            className="checkbox path flex capitalize"
                            htmlFor="asap"
                          >
                            <input
                              type="radio"
                              id="asap"
                              name="duration"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value="ASAP - max one month"
                              checked={radioChecked[0]}
                              onClick={() => {
                                replaceItemInStateRadio(0);
                              }}
                            />
                            <svg viewBox="0 0 21 21">
                              <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186" />
                            </svg>
                            <span
                              className="ml-2"
                              style={{ lineHeight: '23px' }}
                            >
                              ASAP - max one month
                            </span>
                          </label>
                        </div>
                        <div className="grid pb-1 xl:pb-0">
                          <label
                            className="checkbox path flex capitalize"
                            htmlFor="two-months"
                          >
                            <input
                              type="radio"
                              id="two-months"
                              name="duration"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value="1-2 Months"
                              checked={radioChecked[1]}
                              onClick={() => {
                                replaceItemInStateRadio(1);
                              }}
                            />
                            <svg viewBox="0 0 21 21">
                              <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186" />
                            </svg>
                            <span
                              className="ml-2"
                              style={{ lineHeight: '23px' }}
                            >
                              1-2 Months
                            </span>
                          </label>
                        </div>
                        <div className="grid pb-1 xl:pb-0">
                          <label
                            className="checkbox path flex capitalize"
                            htmlFor="three-months-plus"
                          >
                            <input
                              type="radio"
                              id="three-months-plus"
                              name="duration"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value="3+ Months"
                              checked={radioChecked[2]}
                              onClick={() => {
                                replaceItemInStateRadio(2);
                              }}
                            />
                            <svg viewBox="0 0 21 21">
                              <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186" />
                            </svg>
                            <span
                              className="ml-2"
                              style={{ lineHeight: '23px' }}
                            >
                              3+ Months
                            </span>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className="flex flex-col pb-2">
                        <label htmlFor="message" className="leading-loose font-semibold">
                          Message{' '}
                          {formik.touched.message && formik.errors.message ? (
                            <span
                              className="text-red-500 font-bold"
                              title={formik.errors.message}
                            >
                              *
                            </span>
                          ) : null}
                        </label>
                        <textarea
                          name="message"
                          rows="4"
                          cols="30"
                          maxLength="524"
                          // className="border px-4 py-2 focus:border-gray-500 focus:bg-white w-full sm:text-sm border-gray-400 rounded-sm focus:outline-none text-gray-800 bg-transparent"
                          className={`px-3 py-2 border focus:ring-1 focus:ring-gray-800 focus:border-transparent focus:bg-white w-full sm:text-sm border-gray-600 rounded-sm focus:outline-none text-gray-800 bg-transparent ${formik.touched.message && formik.errors.message
                            ? 'focus:ring-1 focus:ring-red-500 focus:border-none border-red-500'
                            : 'focus:border-none'
                            }`}
                          placeholder="Your message goes here"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.message}
                        />
                        {/* <p className='mt-4 text-opacity-50 text-sm'><span className='text-lg'>ⓘ</span> Need NDA first? E-mail me <a href="mailto:adnan@adncodez.com" className='text-black underline'>adnan@adncodez.com</a></p> */}
                      </div>
                    </fieldset>
                    <button
                      type="submit"
                      aria-label="Submit contact form"
                      // className="bg-gray-500 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none"
                      // className="btn-primary flex"
                      className={`btn-primary flex ${isActive ? 'clicked pointer-events-none' : ''
                        }`}
                      id="form-btn"
                    >
                      <span>{isActive ? 'Sent' : 'Send'}</span>
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 512 512"
                        enableBackground="new 0 0 512 512"
                        xmlSpace="preserve"
                      >
                        <path
                          id="paper-plane-icon"
                          d="M462,54.955L355.371,437.187l-135.92-128.842L353.388,167l-179.53,124.074L50,260.973L462,54.955z
          M202.992,332.528v124.517l58.738-67.927L202.992,332.528z"
                        />
                      </svg>
                    </button>
                  </form>
                  {/* <p>Need NDA first? E-mail me at adnan@adncodez.com</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
