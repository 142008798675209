import React from 'react';
import NavBar from './NavBar';
// import graphicArtOne from '../../static/graphicOne.svg';
import graphicTopLeft from '../../static/graphicTopLeft.svg';
import graphicTopRight from '../../static/graphicTopRight.svg';
import graphicArtCenter from '../../static/graphicArtCenter.svg';
// import graphicBottom from '../../static/graphicBottomFrame.svg';
import HeroCards from './HeroCards';
import SubHero from './SubHero';

export default function Hero() {
  return (
    <>
      {/* <h1 className="text-red-500">here goes the HOW?</h1> */}
      {/* bg-gradient-to-br from-purple-200 via-purple-400 to-purple-800 */}
      <section className="overflow-x-hidden" id='heroSection'>
        <div className="w-full 4xl:px-48">
          <img
            src={graphicArtCenter}
            alt="pic"
            className="-z-10 w-11/12 absolute transform translate-x-4 -translate-y-2 4xl:-translate-y-24 4xl:translate-x-96 4xl:w-6/12 "
          />
          <img
            src={graphicTopLeft}
            alt="pic"
            className="-z-10 w-auto absolute transform -translate-x-14 -translate-y-20 4xl:left-0 4xl:w-3/12 "
          />
          <img
            src={graphicTopRight}
            alt="pic"
            className="-z-10 absolute transform right-0 4xl:w-3/12 4xl:-translate-y-20"
          />
          <NavBar />
          <div
            className="mx-auto py-12 px-4
            md:px-4
            lg:pt-8
            xl:flex"
          >
            <div
              className="flex flex-col flex-grow w-full items-start text-center pb-12
              lg:text-left lg:pb-6
              xl:pb-0"
            >
              <div className="lg:mb-8 lg:mt-4 xl:my-14 2xl:my-16 ">
                {/* text-shadow: 0px 1px 2px rgb(30 29 39 / 19%), 1px 2px 4px rgb(54 64 147 / 18%) */}
                <h1
                  className="text-3xl text-left font-bold text-gray-800 text-shadow-default 4xl:leading-12
                  sm:text-4xl sm:mr-12
                  md:w-4/5 md:text-6xl
                  xl:w-5/6
                  5xl:pr-52"
                >
                  Leveraging software solutions to grow your business
                </h1>
                <p
                  className="text-left mr-4 mt-4 text-gray-700
                  sm:text-lg
                  md:text-2xl 
                  5xl:text-3xl"
                >
                  With the use of cutting-edge technologies
                </p>
              </div>
              <div className="mt-8 lg:mt-4">
                <SubHero data="Satisfied Clients" dataCount="5" />
                <SubHero data="Delivered Projects" dataCount="20" />
              </div>
            </div>
            <div
              // flex
              className="grid grid-rows-vertical overflow-y-hidden
              md:pt-6
              xl:w-9/12 xl:grid-rows-horizontal xl:pt-0 
              2xl:w-full
              4xl:grid-rows-horizontalLarge"
            >
              {/* object-cover */}
              {/* <img src={WhatWeDo} alt="art" /> */}
              <HeroCards />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
