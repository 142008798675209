import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { StaticImage } from 'gatsby-plugin-image';

export default function NavBar() {
  const [toggle, setToggle] = useState(false);
  return (
    <nav role="navigation">
      <div
        className="grid grid-cols-navigationMobile items-center px-4 py-2
         md:px-4  md:grid-cols-navigation 5xl:navigationLarge"
      >
        <div className="z-10">
          <a href="/" rel="home" aria-label="Go to AdnCodez home page" >
            <StaticImage
              src="../../static/adncodez-revamp.png"
              alt="logo"
              width={172}
            // height={26}
            />
          </a>
        </div>
        <div className="ml-auto lg:hidden col-start-3">
          <button
            className={`flex items-center px-3 py-2 outline-none focus:outline-none z-20 relative hamburger hamburger--squeeze ${toggle ? 'is-active' : ''
              }`}
            type="button"
            onClick={() => setToggle(!toggle)}
            aria-label="Toggle navigation menu on mobile"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
        <div
          id="navigation-items"
          className={`lg:flex justify-center ${toggle ? '' : 'hidden'
            } col-start-2 col-end-4 lg:col-end-3`}
        >
          <ul
            className={`flex flex-col justify-center text-center md:flex-row items-center md:space-x-2 lg:space-x-0 ${toggle
              ? 'custom-mobile-nav absolute top-1 right-6 py-6 rounded rounded-b-xl z-10'
              : ''
              }`}
            style={{}}
          >
            <li>
              <Link
                to="aboutSection"
                spy
                smooth
                offset={0}
                duration={1500}
                className={`block px-4 py-1 sm:p-1 lg:p-2 lg:px-4 text-gray-800 font-semibold text-lg capitalize cursor-pointer ${toggle
                  ? 'py-2 font-semibold text-2xl hover:bg-gray-100 hover:bg-opacity-5 hover:text-gray-800 rounded'
                  : ''
                  }
                3xl:text-xl 
                `}
                title="Go to about section"
                onClick={() => setToggle(false)}
                aria-label="Go to about section"
              >
                about
              </Link>
            </li>
            <li>
              <Link
                to="serviceSection"
                spy
                smooth
                offset={-70}
                duration={2500}
                className={`block px-4 py-1 sm:p-1 lg:p-2 lg:px-4 text-gray-800 font-semibold text-lg capitalize cursor-pointer ${toggle
                  ? 'py-2 font-semibold text-2xl hover:bg-gray-100 hover:bg-opacity-5 hover:text-gray-800 rounded '
                  : ''
                  }                 
                3xl:text-xl`}
                title="Go to services section"
                onClick={() => setToggle(false)}
                aria-label="Go to services section"
              >
                services
              </Link>
            </li>
            <li>
              <Link
                to="projectSection"
                spy
                smooth
                offset={0}
                duration={3000}
                className={`block px-4 py-1 sm:p-1 lg:p-2 lg:px-4 text-gray-800 font-semibold text-lg capitalize cursor-pointer ${toggle
                  ? 'py-2 font-semibold text-2xl hover:bg-gray-100 hover:bg-opacity-5 hover:text-gray-800 rounded'
                  : ''
                  }                 
                3xl:text-xl`}
                title="Go to projects section"
                onClick={() => setToggle(false)}
                aria-label="Go to projects section"
              >
                projects
              </Link>
            </li>
            <li>
              <Link
                to="testimonialSection"
                spy
                smooth
                offset={0}
                duration={4500}
                className={`block px-4 py-1 sm:p-1 lg:p-2 lg:px-4 text-gray-800 font-semibold text-lg capitalize cursor-pointer ${toggle
                  ? 'py-2 font-semibold text-2xl hover:bg-gray-100 hover:bg-opacity-5 hover:text-gray-800 rounded'
                  : ''
                  }
                3xl:text-xl`}
                title="Go to testimonials section"
                onClick={() => setToggle(false)}
                aria-label="Go to testimonials section"
              >
                testimonials
              </Link>
            </li>
            <li>
              <Link
                to="contactSection"
                spy
                smooth
                offset={0}
                duration={4500}
                className={`block lg:hidden px-4 py-1 sm:p-1 lg:p-2 lg:px-4 text-gray-800 font-semibold text-lg capitalize cursor-pointer ${toggle
                  ? 'py-2 font-semibold text-2xl hover:bg-gray-100 hover:bg-opacity-5 hover:text-gray-800 rounded'
                  : ''
                  }
                3xl:text-xl`}
                title="Go to contact section"
                onClick={() => setToggle(false)}
                aria-label="Go to contact section"
              >
                contact me
              </Link>
            </li>
          </ul>
        </div>
        <div className="hidden lg:block col-start-3 lg:row-start-1">
          <ul className="flex flex-row justify-end">
            <li className="rounded-3xl text-lg">
              <Link
                to="contactSection"
                spy
                smooth
                offset={0}
                duration={3000}
                className="btn-secondary capitalize 3xl:text-xl font-semibold cursor-pointer "
                title="Contact me"
                // onClick={() => setToggle(false)}
                aria-label="contact section"
              >
                contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
