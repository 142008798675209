import React, { useState, useEffect } from 'react';
import InView from 'react-intersection-observer';
import projectsData from './data/projectsData';
import ProjectTemplate from './ProjectTemplate';

const projectsPerPage = 4;
let arrayForHoldingProjects = [];

const ProjectComp = () => {
  const [projectsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(4);
  const [animateBounce, setAnimateBounce] = useState(false);

  const loopWithSlice = (start, end) => {
    const slicedProjects = projectsData.slice(start, end);
    arrayForHoldingProjects = [...arrayForHoldingProjects, ...slicedProjects];
    setPostsToShow(arrayForHoldingProjects);
  };

  useEffect(() => {
    loopWithSlice(0, projectsPerPage);
  }, []);

  const handleShowMoreProject = () => {
    loopWithSlice(next, next + (projectsPerPage - 2));
    setNext(next + (projectsPerPage - 2));
    // console.log(next, arrayForHoldingProjects.length);
  };

  return (
    <section className="4xl:px-48 overflow-x-hidden">
      <ProjectTemplate projectsToRender={projectsToShow} />
      <InView as="div" onChange={(inView) => setAnimateBounce(inView)}>
        <div className="w-full flex justify-center mb-12 lg:mb-24">
          <button
            type="button"
            className={`btn-secondary shadow-2xl text-base 3xl:text-lg font-semibold py-2 rounded-full px-2 ${
              animateBounce ? 'bounce' : ''
            }`}
            onClick={handleShowMoreProject}
            title="Load more projects"
            aria-label="Load more projects"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
        </div>
      </InView>
    </section>
  );
};

export default ProjectComp;
