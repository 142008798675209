import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const GetImgViaFilename = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n) =>
        n.node.relativePath.includes(props.filename)
      );

      if (!image) {
        return null;
      }

      //   const imageFluid = image.node.childImageSharp.fluid;
      //   return <Img alt={props.alt} fluid={imageFluid} />;
      return (
        <GatsbyImage
          alt={props.alt}
          image={getImage(image.node)}
          className={props.className}
          onMouseEnter={props.onMouseEnter}
          // onMouseLeave={props.onMouseLeave}
        />
      );
    }}
  />
);
export default GetImgViaFilename;
