import React from 'react';
import { motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import SectionTitle from './SectionTitle';
import FadeInWhenVisible from '../utils/FadeInWhenVisible';
import graphicArtCenter from '../../static/graphicArtCenter.svg';
import { Link } from 'react-scroll';


export default function About() {
  const itemOne = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -144 },
  };
  const itemTwo = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 144 },
  };
  return (
    <section className="4xl:px-48 min-h-700 overflow-hidden" id="aboutSection">
      <div className="py-16 sm:py-24 px-4">
        <SectionTitle
          title="about me"
          subTitle="Who's taking care of your projects"
        />
        <div className="py-4 px-4 xl:grid xl:grid-cols-2 xl:gap-8">
          <div className="mb-10">
            <img
              src={graphicArtCenter}
              alt="graphic art background"
              className="-z-10 absolute transform -translate-x-1/3 -translate-y-1/3 xl:w-5/12 w-full  "
            />
            <FadeInWhenVisible>
              <motion.div
                variants={{ ...itemOne }}
                transition={{ duration: '0.8' }}
                className="flex justify-center"
              >
                <div className="w-10/12 lg:w-full lg:text-center">
                  <StaticImage
                    src="../../static/unsplash-removebg.png"
                    alt="portrait pic"
                    width={550}
                    height={350}
                  // className="rounded rounded-bl-sm rounded-br-xl rounded-tl-xl rounded-tr-sm"
                  // style={{
                  //   boxShadow: '-5px 5px 45px rgba(0, 0, 0, 0.15)',
                  // }}
                  />
                </div>
              </motion.div>
            </FadeInWhenVisible>
          </div>

          <FadeInWhenVisible>
            <motion.div
              variants={{ ...itemTwo }}
              transition={{ duration: '0.8' }}
            >
              <p
                className="text-lg
                md:text-xl lg:px-12 break-words"
              >
                Adnan Habbat, I'm a freelance web developer & designer. I help startups and enterprises tackle their development obstacles to get the results they hope for and introduce their services/products to the world, promptly at a competitive cost. Adopting leading-edge technologies to make the process fast, seamless, and secure.
              </p>
              <div className="flex justify-center lg:px-12 mt-10 2xl:mt-16 xl:justify-start">
                <Link
                  to="contactSection"
                  spy
                  smooth
                  offset={0}
                  duration={3210}
                  className={`btn-primary cursor-pointer`}
                  title="Get A Quote"
                  aria-label="go to contact section"
                >
                  Get A Quote
                </Link>
              </div>
            </motion.div>
          </FadeInWhenVisible>
        </div>
      </div>
    </section>
  );
}
