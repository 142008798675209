import React, { useRef } from 'react';
import Carousel from 'react-elastic-carousel';
import SectionTitle from './SectionTitle';
import TestimonialCard from './TestimonialCard';

export default function Testimonials() {
  const data = [
    {
      id: 1,
      message:
        'Adnan was easy to work & communicate with. He brought some good ideas to the project. Would hire him again. Next job on already on its way!',
      name: 'D.Steve',
      company: 'CEO',
    },
    {
      id: 2,
      message: 'Excellent work, transparent communication also a friendly guy.',
      name: 'Alex',
      company: 'Startup Founder',
    },
    {
      id: 3,
      message: 'Great adaptation and contribution to ongoing project',
      name: 'Peter',
      company: 'Lead Engineer',
    },
    {
      id: 4,
      message:
        'Avalaible when needed and really competent, and open to learn new stuff. I recommend.',
      name: 'B.kyla',
      company: 'Startup Founder',
    },
    {
      id: 5,
      message: 'Provided high quality work in a timely manner.',
      name: 'Sam',
      company: 'Marketer',
    },
  ];

  const carouselRef = useRef(0);
  // const onNextStart = (currentItem, nextItem) => {
  //   if (currentItem.index === nextItem.index) {
  //     carouselRef.current.goTo(0);
  //   }
  // };
  // const onPrevStart = (currentItem, nextItem) => {
  //   if (currentItem.index === nextItem.index) {
  //     carouselRef.current.goTo(data.length);
  //   }
  // };
  return (
    <section
      className="4xl:px-48 py-12 sm:py-16 md:px-4 overflow-x-hidden"
      id="testimonialSection"
    >
      <SectionTitle
        title="testimonials"
        subTitle="Guaranteed client satisfaction"
      />
      <Carousel
        className="px-1"
        itemsToShow={2}
        breakPoints={[
          { width: 320, itemsToShow: 1 },
          { width: 600, itemsToShow: 1 },
          { width: 768, itemsToShow: 2 },
          { width: 1150, itemsToShow: 3 },
          { width: 1600, itemsToShow: 4 },
        ]}
        pagination={false}
        itemPadding={[0, 10]}
        autoPlaySpeed={2000}
        tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
        transitionMs={878}
        ref={carouselRef}
      // onPrevStart={onPrevStart}
      // onNextStart={onNextStart}
      >
        {data.map((item) => (
          <TestimonialCard
            key={item.id}
            message={item.message}
            name={item.name}
            company={item.company}
            position="left"
          />
        ))}
      </Carousel>
    </section>
  );
}
