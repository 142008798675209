import React from 'react';
import { motion } from 'framer-motion';
import SectionTitle from './SectionTitle';
import Card from './Card';
import FadeInWhenVisible from '../utils/FadeInWhenVisible';
import useWindowSize from '../utils/useWindowSize';
import Delayed from '../utils/Delayed';
import featureIconOne from '../../static/dev-feature.svg';
import featureIconTwo from '../../static/design-feature.svg';
import featureIconThree from '../../static/growth-feature.svg';

export default function Features() {
  const item1mobile = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -144 },
  };
  const item2mobile = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 144 },
  };
  const item3mobile = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -144 },
  };
  const item1laptop = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -144 },
  };
  const item2laptop = {
    visible: { opacity: 1, y: 0, x: 0 },
    hidden: { opacity: 0, y: 144, x: 0 },
  };
  const item3laptop = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 144 },
  };

  const [width] = useWindowSize();

  return (
    <>
      <section className="4xl:px-48 min-h-700 overflow-hidden" id="serviceSection">
        <div className="py-12 sm:py-16 md:px-4 ">
          <SectionTitle
            title="Services"
            subTitle="Software development simplified"
          />
          <Delayed>
            <div className="flex flex-wrap xl:flex-nowrap justify-center">
              <FadeInWhenVisible>
                <motion.div
                  variants={
                    width < 1024 ? { ...item1mobile } : { ...item1laptop }
                  }
                  transition={{ duration: '0.8' }}
                >
                  <Card
                    title="Web Design"
                    desc="A simple design approach to craft unique, accessible user interfaces for your website or web application. Which compliments your brand and visually impress your customers. This step is a crucial part of your online marketing presence."
                    shift=""
                    position="l"
                    iconPath={featureIconTwo}
                  />
                </motion.div>
              </FadeInWhenVisible>
              <FadeInWhenVisible>
                <motion.div
                  variants={
                    width < 1024 ? { ...item2mobile } : { ...item2laptop }
                  }
                  transition={{ duration: '0.8' }}
                >
                  <Card
                    title="Web Development"
                    desc="By focusing on the latest web standards and using modern web technologies, I create flexible and scalable business-driven solutions, that are highly customized and fully integrated at an efficient cost and in a timely manner."
                    shift="true"
                    position="c"
                    iconPath={featureIconOne}
                  />
                </motion.div>
              </FadeInWhenVisible>
              <FadeInWhenVisible>
                <motion.div
                  variants={
                    width < 1024 ? { ...item3mobile } : { ...item3laptop }
                  }
                  transition={{ duration: '0.8' }}
                >
                  <Card
                    title="Automation & Optimization"
                    desc="Whether its performance or security audit, maintenance or automation, I help your businesses with making the necessary adjustments to the current project, website or web app, and shape it to convert as never before."
                    shift=""
                    position="r"
                    iconPath={featureIconThree}
                  />
                </motion.div>
              </FadeInWhenVisible>
            </div>
          </Delayed>
        </div>
      </section>
    </>
  );
}
