import React from 'react';
import { Link } from 'react-scroll';


export default function FooterCol(props) {
  return (
    <>
      <div className="p-5 w-1/2 sm:w-4/12 md:w-3/12">
        {/* <div className="text-lg uppercase mb-6 font-bold">Navigation</div> */}
        <Link
          to="projectSection"
          spy
          smooth
          offset={0}
          duration={750}
          className="mb-2 block text-md capitalize cursor-pointer"
          title="Go to projects section"
          // onClick={() => setToggle(false)}
          aria-label="projects section"
        >
          projects
        </Link>
        <Link
          to="testimonialSection"
          spy
          smooth
          offset={0}
          duration={550}
          className="mb-2 block text-md capitalize cursor-pointer"
          title="Go to testimonials section"
          // onClick={() => setToggle(false)}
          aria-label="testimonials section"
        >
          testimonials
        </Link>
        <Link
          to="contactSection"
          spy
          smooth
          offset={0}
          duration={250}
          className="mb-2 block text-md capitalize cursor-pointer"
          title="Go to contact section"
          // onClick={() => setToggle(false)}
          aria-label="contact section"
        >
          contact
        </Link>
      </div>
      {/* <div className="p-5 w-1/2 sm:w-4/12 md:w-3/12">
        <div className="text-lg uppercase mb-6 font-bold">Contact Me</div>
        <a
          href="https://www.adncodez.com"
          className="mb-2 block text-md capitalize cursor-pointer"
        >
          live chat
        </a>
        <a
          href="https://www.adncodez.com"
          className="mb-2 block text-md capitalize cursor-pointer"
        >
          schedule meeting
        </a>
      </div> */}
    </>
  );
}
