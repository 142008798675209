import React from 'react';
import FooterCol from './FooterCol';
import FooterColTwo from './FooterColTwo';
import FooterColLogo from './FooterColLogo';
// import FooterColRight from './FooterColRight';

export default function Footer() {
  return (
    <>
      <footer className="pt-10 sm:mt-0 ">
        <div className="max-w-6xl m-auto flex flex-wrap justify-left 2xl:max-w-none 2xl:px-52">
          <FooterColLogo />
          <FooterCol />
          <FooterColTwo />
          <div className="p-5 w-1/2 sm:w-4/12 md:w-3/12">
            {/* <div className="text-lg uppercase mb-6 font-bold">Contact Me</div> */}
            {/* <a
              href="#"
              className="my-2 block text-md capitalize"
            >
              live chat
            </a> */}
            <a
              href="https://calendly.com/adncodez/15min"
              className="mb-2 block text-md capitalize rounded animated-underline hover:shadow-purpleSmall text-center w-60"
            >
              <span className='px-0 lg:px-1 font-semibold hover:border-purple-300'>schedule a meeting</span>
            </a>
          </div>
          {/* <FooterColRight /> */}
        </div>
        <div className="w-10/12 max-h-0.5 h-1 animated-underline bg-opacity-40 flex mx-auto rounded-full" />
        <div
          className="flex pb-5 px-3 m-auto pt-5 justify-center text-sm flex-col max-w-6xl
            md:flex-row 
            2xl:max-w-none 2xl:px-52"
        >
          <div className="text-md capitalize">
            © Copyright {new Date().getFullYear()} ─ All Rights Reserved
          </div>
        </div>
      </footer>
    </>
  );
}
