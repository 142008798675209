import React, { useState } from 'react';
import '../styles/hero-cards.css';
import { StaticImage } from 'gatsby-plugin-image';
import clickMe from '../../static/click-me.svg';
// import Image from './Image';

export default function HeroCards() {
  const [clickState, setClickState] = useState({
    cardA: false,
    cardB: false,
    cardC: false,
  });

  return (
    <div className="global-wrapper">
      <div className="">
        {/* <img
          src={clickMe}
          alt="pic"
          className="w-10 h-10"
          style={{ position: 'absolute', right: '240px', bottom: '-40px' }}
        /> */}
        <div className="flex justify-center">
          <div className="cards">
            <div
              className={`inner-card card3 rounded absolute ${clickState.cardC ? 'clicked' : ''
                }`}
            >
              <div
                className={`rounded ${clickState.cardC ? '' : ''}`}
                onClick={() => {
                  setClickState({
                    cardC: !clickState.cardC,
                    cardA: false,
                    cardB: false,
                  });
                }}
              >
                <StaticImage
                  src="../../static/hero-section-images/scale-hero-card.svg"
                  alt="hero img 3"
                  className={`w-56 5xl:w-64  rounded-md bg-purplish-lightest  ${clickState.cardC ? '' : ''
                    }`}
                />
              </div>
            </div>
            <div
              className={`inner-card card2 rounded absolute  ${clickState.cardB ? 'clicked' : ''
                }`}
            >
              <div
                className={`rounded ${clickState.cardB ? '' : ''}`}
                onClick={() => {
                  setClickState({
                    cardB: !clickState.cardB,
                    cardC: false,
                    cardA: false,
                  });
                }}
              >
                <StaticImage
                  src="../../static/hero-section-images/design-hero-card.svg"
                  alt="hero img 1"
                  className={`w-56 5xl:w-64 rounded-md bg-purplish-lightest ${clickState.cardB ? '' : ''
                    }`}
                />
              </div>
            </div>
            <div
              className={`inner-card card1 rounded relative  ${clickState.cardA ? 'clicked' : ''
                }`}
            >
              <div
                className={`rounded ${clickState.cardA ? 'clicked' : ''}`}
                onClick={() => {
                  setClickState({
                    cardA: !clickState.cardA,
                    cardB: false,
                    cardC: false,
                  });
                }}
              >
                <StaticImage
                  src="../../static/hero-section-images/build-hero-card.svg"
                  alt="hero img 2"
                  className={`w-56 5xl:w-64 rounded-md bg-purplish-lightest ${clickState.cardA ? ' ' : ''
                    }`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
