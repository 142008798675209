import { motion, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

export default function FadeInWhenVisible({ children, trigger }) {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.3, triggerOnce: trigger });
  //   const list = {visible: { opacity: 1, y: 0 },hidden: { opacity: 0, y: -144 }};
  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
    // transition={{ duration: 0.2 }}
    //   variants={list}
    >
      {children}
    </motion.div>
  );
}
