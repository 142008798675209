import React from 'react';
import { Link } from 'react-scroll';


export default function FooterCol(props) {
  return (
    <>
      <div className="p-5 w-1/2 sm:w-4/12 md:w-3/12">
        {/* <div className="text-lg uppercase mb-6 font-bold">Navigation</div> */}
        <Link
          to="heroSection"
          spy
          smooth
          offset={0}
          duration={2000}
          className="mb-2 block text-md capitalize cursor-pointer"
          title="Go to hero section"
          // onClick={() => setToggle(false)}
          aria-label="hero section"
        >
          Home
        </Link>
        <Link
          to="aboutSection"
          spy
          smooth
          offset={0}
          duration={1550}
          className="mb-2 block text-md capitalize cursor-pointer"
          title="Go to about section"
          // onClick={() => setToggle(false)}
          aria-label="about section"
        >
          About
        </Link>
        <Link
          to="servicesSection"
          spy
          smooth
          offset={0}
          duration={1000}
          className="mb-2 block text-md capitalize cursor-pointer"
          title="Go to services section"
          // onClick={() => setToggle(false)}
          aria-label="services section"
        >
          services
        </Link>
      </div>
      {/* <div className="p-5 w-1/2 sm:w-4/12 md:w-3/12">
        <div className="text-lg uppercase mb-6 font-bold">Contact Me</div>
        <a
          href="https://www.adncodez.com"
          className="mb-2 block text-md capitalize cursor-pointer"
        >
          live chat
        </a>
        <a
          href="https://www.adncodez.com"
          className="mb-2 block text-md capitalize cursor-pointer"
        >
          schedule meeting
        </a>
      </div> */}
    </>
  );
}
