import React from 'react';
import { motion } from 'framer-motion';

import FadeInWhenVisible from '../utils/FadeInWhenVisible';

export default function SectionTitle({ title, subTitle }) {
  const sta = {
    hidden: { opacity: 0, y: -50, scale: 1.2 },
    visible: { opacity: 1, y: 0, scale: 1 },
  };

  return (
    <FadeInWhenVisible trigger="true">
      <motion.div variants={sta} transition={{ duration: '1' }}>
        <div className="mb-20 text-center">
          <h1
            className="capitalize mb-4 text-3xl font-bold leading-10 tracking-tight 
        sm:text-4xl 
        md:"
          >
            {title}
          </h1>
          <p
            className="mx-8 leading-5 
        lg:mx-auto lg:w-3/4 xl:w-2/4"
          >
            {subTitle}
          </p>
          <div className="flex mt-6 justify-center">
            <div className="w-28 h-1 rounded-full animated-underline inline-flex" />
          </div>
        </div>
      </motion.div>
    </FadeInWhenVisible>
  );
}
