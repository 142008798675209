const projects = [
  {
    id: 0,
    title: 'Crypto Catalyst',
    pic: 'project1',
    desc: 'Is a website for a staking pool service, built with speed and accessibility as a priority using Nextjs, Tailwindcss and uses serverless functions to make a batch of API calls. It also has a blog section that supports markdown for a smooth writing experience.',
    bulletPoints: {
      bulletPointsId: 0,
      bulletPointsData: [
        'Design',
        'Responsive',
        'Development',
        'SEO',
        'Copyright',
        'APIs',
      ],
    },
    shift: false,
    screenshotLink: 'cryptocatalyst-com.png',
    screenshotAlt: 'Crypto catalyst website development',
    livePreview: 'https://www.crypto-catalyst.com/',
  },
  {
    id: 1,
    title: 'Restaurant Menu Web App',
    pic: 'project2',
    desc: 'With the rise of digitalization in restaurants and coffee shops, I have built this web application to tackle the need for a paper menu. It gives the user the list of meals provided also an estimated price for selected ones. The owner can edit the store inventory details add or remove meals accordingly. This project was built using React, Firebase for data storage and live data modifications as well as authentication.',
    bulletPoints: {
      bulletPointsId: 1,
      bulletPointsData: [
        'Design',
        'Responsive',
        'Development',
        'Authentication',
        'Real time DB',
        'Scalable',
      ],
    },
    shift: true,
    screenshotLink: 'restaurant-dashboard.png',
    screenshotAlt: 'Restaurant menu single page application',
    livePreview: 'https://boring-johnson-800ed4.netlify.app/',
  },
  // {
  //   id: 2,
  //   title: 'Pizza Shop',
  //   pic: 'project3',
  //   desc: 'Is a web application built using React, Gatsby and Sanity CMS, and uses netlify functions to send order details to an email address',
  //   bulletPoints: {
  //     bulletPointsId: 2,
  //     bulletPointsData: [
  //       'Design',
  //       'Responsive',
  //       'Development',
  //       'CMS',
  //       'Copyright',
  //       'Blazing Fast',
  //     ],
  //   },
  //   shift: false,
  //   screenshotLink: 'pizza+shopp.png',
  //   screenshotAlt: 'alt text here',
  //   livePreview: false,
  // },
  {
    id: 3,
    title: 'Checkout Plugin',
    desc: 'This project consists of a WordPress/WooCommerce plugin, built to simplify the checkout process the user has to go through to make a service purchase. it makes a bunch of API calls on the checkout page as well as after order submission. this plugin also changes the structure of the WooCommerce orders page to add API response messages, some custom fields so that staff can easily manage orders, follow up, or spot orders that errors.',
    bulletPoints: {
      bulletPointsId: 3,
      bulletPointsData: [
        'WordPress',
        'WooCommerce',
        'Development',
        'APIs',
        'Custom',
        'OOP',
      ],
    },
    shift: false,
    screenshotLink: 'checkout+plugin.png',
    screenshotAlt: 'Wordpress plugin development',
    livePreview: false,
  },
  {
    id: 4,
    title: 'Free trial plugin',
    desc: "This WordPress plugin's main functionality is to limit the user after claiming one or more free trials as set by the admin. It was used for a service fulfilled using an API, it can also work for digital products such as downloadable ebooks. once the user gets restricted the service no longer shows for that specific user based on email, name, IP, location, and number of submissions.",
    bulletPoints: {
      bulletPointsId: 4,
      bulletPointsData: [
        'WordPress',
        'Development',
        'Databases',
        'APIs',
        'Custom',
        'OOP',
      ],
    },
    shift: true,
    screenshotLink: 'free+trial+plugin.png',
    screenshotAlt: 'Custom plugin development',
    livePreview: false,
  },
  {
    id: 5,
    title: 'Hotonsocials Website',
    desc: 'Is a WordPress eCommerce ready website for selling a digital service, built with the use of Elementor, fully optimized & responsive with a unique user experience and custom graphic art',
    bulletPoints: {
      bulletPointsId: 5,
      bulletPointsData: [
        'Design',
        'Responsive',
        'Development',
        'Graphics',
        'Copyright',
        'SEO',
      ],
    },
    shift: false,
    screenshotLink: 'hotonsocials-com.png',
    screenshotAlt: 'WordPress responsive ecommerce website',
    livePreview: false,
  },
  {
    id: 6,
    title: 'AdnCodez Website V2',
    desc: 'The latest version of AdnCodez website is built with speed as a priority as well as future proof functionality, the framework used is Gatsby and Sanity CMS for managing future blog posts.',
    bulletPoints: {
      bulletPointsId: 6,
      bulletPointsData: [
        'Design',
        'Responsive',
        'Development',
        'Blazing Fast',
        'Scalable',
        'SEO',
      ],
    },
    shift: true,
    screenshotLink: 'adncodez+v2.png',
    screenshotAlt: 'Blazing fast portfolio website',
    livePreview: 'https://adncodez.com/',
  },
  {
    id: 7,
    title: 'Python XLS script',
    desc: 'Is used to filter, sort, modify data of a large XLS file, with tens of rows and thousands of columns and outputs ready to use data on a web application, via a Postgres database.',
    bulletPoints: {
      bulletPointsId: 7,
      bulletPointsData: [
        'Script',
        'RegEx',
        'Filter',
        'Data',
        'Python OOP',
        'Modular',
      ],
    },
    shift: false,
    screenshotLink: 'Python+XLS+script.png',
    screenshotAlt: 'Python script for XLS files',
    livePreview: false,
  },
  {
    id: 8,
    title: 'Python Aliexpress Scrapper',
    desc: 'This is a set of scripts used to scrape specific Aliexpress categories of products and gather a bunch of important data, such as product image link, price, availability, shipping country, and price of shipping.',
    bulletPoints: {
      bulletPointsId: 8,
      bulletPointsData: [
        'Script',
        'Scrapping',
        'Ecommerce',
        'SEO',
        'Python OOP',
        'Data',
      ],
    },
    shift: true,
    screenshotLink: 'Python+Aliexpress+Scrapper.png',
    screenshotAlt: 'Python data scrapping',
    livePreview: false,
  },

  {
    id: 9,
    title: 'Dashboard UI',
    desc: 'This is a Bootstrap-based UI dashboard for an enterprise, with multiple components for users connected to view products on the platform, on the other hand, there are other specifically designed components for administrators.',
    bulletPoints: {
      bulletPointsId: 9,
      bulletPointsData: [
        'Design',
        'Responsive',
        'UI',
        'UX',
        'Graphics',
        'Bootstrap',
      ],
    },
    shift: false,
    screenshotLink: 'Dashboard+UI.jpg',
    screenshotAlt: 'Dashboard user interface design',
    livePreview: false,
  },
  {
    id: 10,
    title: 'Adncodez V1 Website',
    desc: 'The first version of AdnCodez website was built from scratch using HTML, SASS and vanilla JS. simple and straightforward portfolio website, fast and responsive, with simple animations.',
    bulletPoints: {
      bulletPointsId: 10,
      bulletPointsData: [
        'Design',
        'Responsive',
        'Development',
        'Copyright',
        'Fast',
        'SEO',
      ],
    },
    shift: true,
    screenshotLink: 'adncodez+V1+Website.png',
    screenshotAlt: 'Portfolio static website',
    livePreview: 'https://www.old-adncodez.netlify.app',
  },
  {
    id: 11,
    title: 'SEO optimized website',
    // desc: 'Two website related to comics, ranked first page google search result, with no backlinks, only webvitals and other tweaks 😉',
    desc: 'This is a side project of mine built to rank on google first search page with no backlinks, only web vitals, and other tweaks 😉.',
    bulletPoints: {
      bulletPointsId: 11,
      bulletPointsData: [
        'Design',
        'Development',
        'Fast',
        'SEO',
        'Web vitals',
        'NO backlinks',
      ],
    },
    shift: false,
    screenshotLink: 'Website+1st+SP.png',
    screenshotAlt: 'Search engine optimized website',
    livePreview: false,
  },
  // {
  //   id: 12,
  //   title: 'Other Projects at @adncodez',
  //   desc: 'In order to view other projects not mentioned on this section ',
  //   bulletPoints: {
  //     bulletPointsId: 12,
  //     bulletPointsData: ['-', '-', '-', '-', '-', '-'],
  //   },
  //   shift: true,
  //   screenshotLink: 'Other.png',
  //   screenshotAlt: 'alt text here',
  //   livePreview: false,
  // },
];
export default projects;
