import { useStaticQuery, graphql } from 'gatsby';

export const useSiteMetadata = () =>
  useStaticQuery(
    graphql`
      query {
        site {
          id
          siteMetadata {
            author
            description
            keywords
            lang
            ogImage
            siteUrl
            title
            favicon {
              ico
              lg
              sm
            }
          }
        }
      }
    `
  );
